import React, { ReactElement, useRef, useEffect, useState } from 'react'; 
import "./style_trustpilot.css"
import { useLocation } from '@reach/router';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';


export const CWTrustpilotReviews = (): ReactElement => {
 
  const location = useLocation();
 
  useEffect(()=>{
    
    const swiperv9 = new Swiper('.swiper-my-choicex', {
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,  
        spaceBetween: 10,    
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,  
            },
            1024: {
                slidesPerView: 4,   
            },
        },
    });

    

    const deal_qstr =  window.location.href.split('?')[1];
    let links = document.getElementsByClassName("trustpilot-pricing-link");
    let link = "/pricing/?swp=home&cg2=1";
    if(location.pathname == '/sale/' || location.pathname == '/sale')
      link = "/pricing/?cg2=1&swp=sl"; //link = "#DealPricing"; //
    else if(location.pathname == '/save/' || location.pathname == '/save')
       link = "/pricing/?cg2=1&swp=sv";//link = "#DealPricing"; //
    else if(location.pathname == '/offer/' || location.pathname == '/offer')
       link = "/pricing/?cg2=1&swp=of";//link = "#DealPricing"; //
    else if(location.pathname == '/lifetime/' || location.pathname == '/lifetime')
       link = "/pricing/?cg2=1&swp=lt";//link = "#DealPricing"; //
    else if(location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal')
      link = "/pricing/?cg2=1&swp=ltd";//link = "#DealPricing"; //
    else if(location.pathname == '/deal/' || location.pathname == '/deal')
       link = "/pricing/?cg2=1&swp=dl";//link = "#DealPricing"; //
    else if((location.pathname == '/promo/' || location.pathname == '/promo') && localStorage.getItem('refid'))
      link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=pr&coupon_code=save10percent&ref="+localStorage.getItem('refid');//link = "#DealPricing"; //
    else if(location.pathname == '/promo/' || location.pathname == '/promo')
      link = "/pricing/?cg2=1&swp=pr";
    else if(location.pathname == '/yt-deal/' || location.pathname == '/yt-deal')
        link = "/pricing/?cg2=1&swp=yt";

    if(typeof deal_qstr !== 'undefined' &&  links !== null){
      let i;
      for (i = 0; i < links.length; i++) {
        links[i].href = link + '&'  + deal_qstr;
      }
    }else if(links !== null){
      let i;
      for (i = 0; i < links.length; i++) {
        links[i].href = link;
      }
    }

    
 

}, []);

 

  return (
    <>
 

    <div className="slider-container css-k89tib ekes0c03">
        <div className="sw_head">What Customers Say About Us</div>
        <div className="review-rating-container">
            <div className="review-rating">
               <strong> Excellent   </strong>
                <div className="stars">
                    <img src="/cwfiles/trustpilot_reviews/stars-5 (1).png" alt="Star" className="star-img"/>
                </div>
                <div className="reviews-info">
                     <strong> 361   </strong> reviews on
                    <img src="/cwfiles/trustpilot_reviews/Shape.png" alt="Trustpilot Logo" className="trustpilot-img"/>
                     <strong> Trustpilot   </strong>
                </div>
            </div>
        </div>

<div className="sw9-trustpilot">
 <button id="prevBtn" className="slider-btn">
                <div className="swiper-button-prev">
                    <img src="/cwfiles/trustpilot_reviews/Aro.png" alt="Previous" className="sw_otp"/>
                </div>
            </button>
             <button id="nextBtn" className="slider-btn">
            <div className="swiper-button-next">
            <img src="/cwfiles/trustpilot_reviews/Aro (1).png" alt="Previous" className="sw_otp"/>
            </div>
            </button>
        <div className="slider-wrapper">
           
            <div className="testimonial-cards-wrapper swiper swiper-my-choicex">
                <div className="testimonial-cards swiper-wrapper">
                    <div className="testimonial-card swiper-slide">
                        <div className="cop_sw">
                            <div className="rating"><img src="/cwfiles/trustpilot_reviews/Trustpilot_ratings_5star-RGB.png" alt="Rating Stars"/>
                            </div>
                            <div className="date">Aug 12, 2024</div>
                        </div>
                        <div className="sw_heading">Sketchwow is have been
nothing but awesome</div>
                        <div className="testimonial-text">
                            Sketchwow is have been nothing
but helpful since I signed up and
they stick to their promises!
Thank you so much for your great
service, dedication and care!
                        </div>
                        <div className="customer-info">
                            <div className="avatar_avatar_sw s1"><span className="">KF</span></div>
                            <div className="customer-name">Kirsten Fitzpatrick</div>
                        </div>
                    </div>

                    <div className="testimonial-card swiper-slide">
                        <div className="cop_sw">
                            <div className="rating"><img src="/cwfiles/trustpilot_reviews/Trustpilot_ratings_5star-RGB.png" alt="Rating Stars"/>
                            </div>
                            <div className="date">Oct 26, 2023</div>
                        </div>

                        <div className="sw_heading">Highly recommended as the
go to Sketch Tool</div>
                        <div className="testimonial-text">
                            Sketch Wow makes designing
simple diagrams for ads or SOP's
or Landing Pages and Websites etc
a breeze I'm not a graphic designer
but I don't need to be as there are
many templates that are so easy.
                        </div>
                        <div className="customer-info">
                            <div className="avatar_avatar_sw s2"><span className="">WU</span></div>
                            <div className="customer-name">Wolfgang Unbehauen</div>
                        </div>
                    </div>

                    <div className="testimonial-card swiper-slide">
                        <div className="cop_sw">
                            <div className="rating"><img src="/cwfiles/trustpilot_reviews/Trustpilot_ratings_5star-RGB.png" alt="Rating Stars"/>
                            </div>
                            <div className="date">Oct 26, 2023</div>
                        </div>

                        <div className="sw_heading">Quick and easy diagram tool
for presentations, marketing
materials, etc.</div>
                        <div className="testimonial-text">
                            Super easy to use diagraming tool
to quickly create diagrams for
marketing, social media, or
presentations. Animations spice
things up without being annoying.
                        </div>
                        <div className="customer-info">
                            <div className="avatar_avatar_sw s3"><span className="">BD</span></div>
                            <div className="customer-name">Brian D.</div>
                        </div>
                    </div>

                    <div className="testimonial-card swiper-slide">
                        <div className="cop_sw">
                            <div className="rating"><img src="/cwfiles/trustpilot_reviews/Trustpilot_ratings_5star-RGB.png" alt="Rating Stars"/>
                            </div>
                            <div className="date">Oct 25, 2023</div>
                        </div>

                        <div className="sw_heading">SketchWow simply looks
different</div>
                        <div className="testimonial-text">
                            The other thing is that it's so easy
and flexible... it is easy to express
what you want to visualize. I am
using it to make meeting minutes
of projects reviews, lately, with
great results.
                        </div>
                        <div className="customer-info">
                            <div className="customer-info">
                            <div className="avatar_avatar_sw s4"><span className="">VA</span></div>
                            <div className="customer-name">Valentino</div>
                        </div>
                        </div>
                    </div>

                    <div className="testimonial-card swiper-slide">
                        <div className="cop_sw">
                            <div className="rating"><img src="/cwfiles/trustpilot_reviews/Trustpilot_ratings_5star-RGB.png" alt="Rating Stars"/>
                            </div>
                            <div className="date">Oct 26, 2023</div>
                        </div>

                        <div className="sw_heading">Keep this a secret!</div>
                        <div className="testimonial-text">
                            SketchWow makes creating "hand drawn" explainers, nice and simple. I've had many compliments on my work, and have been tempted to pretend I drew them entirely myself. But I'm not good at keeping secrets...
                        </div>
                        <div className="customer-info">
                            <div className="avatar_avatar_sw s5"><span className="">NP</span></div>
                            <div className="customer-name">Nicola Pickp</div>
                        </div>
                    </div>

                    <div className="testimonial-card swiper-slide">
                        <div className="cop_sw">
                            <div className="rating"><img src="/cwfiles/trustpilot_reviews/Trustpilot_ratings_5star-RGB.png" alt="Rating Stars"/>
                            </div>
                            <div className="date">Oct 27, 2023</div>
                        </div>

                        <div className="sw_heading">Give boring diagrams a fresh look</div>
                        <div className="testimonial-text">
                            I've been using SketchWow for about a year, mostly for quick eye catching demos for customers, but also to redo some "boring" diagrams from the past.
                        </div>
                        <div className="customer-info">
                            <div className="avatar_avatar_sw s6"><span className="">TK</span></div>
                            <div className="customer-name">Thomas Klettke</div>
                        </div>
                    </div>

                    <div className="testimonial-card swiper-slide">
                        <div className="cop_sw">
                            <div className="rating"><img src="/cwfiles/trustpilot_reviews/Trustpilot_ratings_5star-RGB.png" alt="Rating Stars"/>
                            </div>
                            <div className="date">Oct 26, 2023</div>
                        </div>

                        <div className="sw_heading">Lifetime deal (up to this stage) is a rare Gem!</div>
                        <div className="testimonial-text">
                            In many instances, there is always a catch behind the "lifetime deal" payment option. For SketchWow, I am really grateful that hidden clauses or surprises did not appear.
                        </div>
                        <div className="customer-info">
                            <div className="avatar_avatar_sw s7"><span className="">YA</span></div>
                            <div className="customer-name">Yee Aik Phoay</div>
                        </div>
                    </div>

                    <div className="testimonial-card swiper-slide">
                        <div className="cop_sw">
                            <div className="rating"><img src="/cwfiles/trustpilot_reviews/Trustpilot_ratings_5star-RGB.png" alt="Rating Stars"/>
                            </div>
                            <div className="date">Oct 25, 2023</div>
                        </div>

                        <div className="sw_heading">If you are like me and feel sleepy and…</div>
                        <div className="testimonial-text">
                            If you are like me and feel sleepy and filled with dread just creating another presentation then you NEED SketchWow! I came across this somehow, online, and immediately paid for the pro version.
                        </div>
                        <div className="customer-info">
                            <div className="avatar_avatar_sw s8"><span className="">C</span></div>
                            <div className="customer-name">Chantel</div>
                        </div>
                    </div>

                    <div className="testimonial-card swiper-slide">
                        <div className="cop_sw">
                            <div className="rating"><img src="/cwfiles/trustpilot_reviews/Trustpilot_ratings_5star-RGB.png" alt="Rating Stars"/>
                            </div>
                            <div className="date">Oct 26, 2023</div>
                        </div>

                        <div className="sw_heading">Makes my presentations look amazing</div>
                        <div className="testimonial-text">
                            Makes my presentations look amazing. Super easy to learn, very intuitive. My first time using it I was under some time pressure and it totally delivered.
                        </div>
                        <div className="customer-info">
                            <div className="avatar_avatar_sw s9"><span className="">D</span></div>
                            <div className="customer-name">Donna</div>
                        </div>
                    </div>

                    <div className="testimonial-card swiper-slide">
                        <div className="cop_sw">
                            <div className="rating"><img src="/cwfiles/trustpilot_reviews/Trustpilot_ratings_5star-RGB.png" alt="Rating Stars"/>
                            </div>
                            <div className="date">Oct 25, 2023</div>
                        </div>

                        <div className="sw_heading">Finance can be FUN with SketchWow!</div>
                        <div className="testimonial-text">
                            As a Financial Educator, I have resigned myself to the fact that my teaching topic can be very dull and dry. 
                        </div>
                        <div className="customer-info">
                            <div className="avatar_avatar_sw s10"><span className="">N</span></div>
                            <div className="customer-name">Nina</div>
                        </div>
                    </div>

                </div>
            </div>
           </div>
        </div>
        <div className="sw-feature-first-second">
            <div className="sw-feature-first-second-button">
                <a href="/pricing" className="css-10gfo7v eg4cuq44 trustpilot-pricing-link">Get Sketchwow Now</a>
            </div>
            <div className="sw-feature-first-second-list">
                <div className="sw-feature-first-second-list-main">
                    <div className="sw-feature-first-second-list-content">
                        <div className="sw-feature-first-second-list-image">
                            <img src="/cwfiles/trustpilot_reviews/feature-money1.png"/>
                        </div>
                        <div className="sw-feature-first-second-list-title">
                            Includes FREE <br/> Software Updates
                        </div>
                    </div>
                    <div className="sw-feature-first-second-list-content">
                        <div className="sw-feature-first-second-list-image">
                            <img src="/cwfiles/trustpilot_reviews/feature-money2.png"/>
                        </div>
                        <div className="sw-feature-first-second-list-title">
                            30-Day moneyback <br/> guarantee
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
            
  );

  
};
