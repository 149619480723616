import React, { ReactElement, useRef, useEffect, useState } from 'react'; 
import "./style_yt_shortvids.css"
import { useLocation } from '@reach/router';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';


export const CWYoutubeShortVideos = (): ReactElement => {
 
  const location = useLocation();
 
  useEffect(()=>{
    
    const swiperv12 = new Swiper('.swiper-my-choicex1', {
        slidesPerView: 1,
        spaceBetween: 10,
        centeredSlides: true,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
       
          1024: {
            slidesPerView: 4,
            spaceBetween: 10,
             centeredSlides: false,
             loop: false,
          },
        },
      });

    //   const script = document.createElement('script');
    //   script.src = 'https://embed.voomly.com/embed/embed-build.js';
    //   script.async=true;
    //   document.body.appendChild(script);
}, []);

 

  return (
    <>
 
 <div className="cw-sw12-box">

<h1 className="cw-sw12-heading">See SketchWow In Action</h1>
<div className="cw-sw12-swip-section">
 
    <div className="cw-sw12-swip-section-main">
        <div className="swiper-button-prev"><img src="/cwfiles/yt_shortvids/ar-left.png" alt=""/></div>
        <div className="swiper-button-next"><img src="/cwfiles/yt_shortvids/ar-right.png" alt=""/></div>
        
        <div className="swiper cw-sw12-swip-container swiper-my-choicex1">
            <div className="swiper-wrapper">
                
                    <div className="swiper-slide cw-sw12-swiper-box">
                        <div className="voomly-embed css-voomly-embed" data-id="EAxBQDaG8BjvQxQYX3Ja1at3TU0HcxYi8DNBsAO6TTqAE1RyL" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
                    </div>

                    <div className="swiper-slide cw-sw12-swiper-box">
                        <div className="voomly-embed css-voomly-embed" data-id="wMyNbHg7cJcUVyFkMkcfIpI8iTVARlMqn2aADRZMbc5mNvl8b" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
                    </div>

                    <div className="swiper-slide cw-sw12-swiper-box">
                        <div className="voomly-embed css-voomly-embed" data-id="XABZ2cgKvElenKgRlcEiHkKRqUecKDCchUsOInJMSpSac3B6P" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
                    </div>
      
                    <div className="swiper-slide cw-sw12-swiper-box">
                        <div className="voomly-embed css-voomly-embed" data-id="G4TTppDbwm0JVNUUDIVouY5EdXBA9Fo7F6YVIGocK7jGHwVFD" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
                    </div>
      
                    <div className="swiper-slide cw-sw12-swiper-box">
                        <div className="voomly-embed css-voomly-embed" data-id="l4igzt8KnTUvLwHaPDhAY8AvBJM7ATwz3D1lSy5cYKryIXE7M" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
                    </div>

                    <div className="swiper-slide cw-sw12-swiper-box">
                        <div className="voomly-embed css-voomly-embed" data-id="q18Gyp-DhJ0H3F4e1oQysb7fCko9MOQaG99FFprcNgdOhwqP3" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
                    </div>

                    <div className="swiper-slide cw-sw12-swiper-box">
                        <div className="voomly-embed css-voomly-embed" data-id="wtMWKYXJDNcq-RdXzUqPSe8z6QASZAVDKGLFfyhyCVMSTpUF6" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
                    </div>
            </div>
        </div>
  </div>
</div>
</div>
 
    </>
            
  );

  
};
